import React, { useCallback, useEffect, useMemo } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, applyActionCode, Auth } from "firebase/auth";
import "./App.css";
import {
  defaultHandlerURL,
  defaultMedicalAPIStage,
  firebaseConfig,
  medicalAPIEndpoint,
  medicalAPIKey,
} from "./config";
import axios from "axios";

type Status =
  | "initial"
  | "loading"
  | "success"
  | "networkError"
  | "expiredError"
  | "recoverEmailNotAllowed";

function App() {
  const [status, setStatus] = React.useState<Status>("initial");

  const handleVerifyEmail = useCallback(
    async (auth: Auth, actionCode: string, continueUrl?: string) => {
      try {
        setStatus("loading");
        // verify email
        await applyActionCode(auth, actionCode);
        if (continueUrl) {
          const searchParams = new URL(continueUrl).searchParams;
          const confirmCode = searchParams.get("code");
          const apiStage = searchParams.get("stage") ?? defaultMedicalAPIStage;
          if (confirmCode) {
            // notify email changes to the system
            await axios.post(
              "/firebase/notify-email-change",
              { code: confirmCode },
              {
                baseURL: medicalAPIEndpoint(apiStage),
                headers: { "x-api-key": medicalAPIKey(apiStage) },
              }
            );
          }
        }
        setStatus("success");
      } catch (e: unknown) {
        const errorCode = (e as any).code;
        if (typeof errorCode === "string" && errorCode.startsWith("auth/")) {
          // firebase auth error
          setStatus("expiredError");
        } else {
          setStatus("networkError");
        }
      }
    },
    []
  );

  // execute once when the app starts
  useEffect(() => {
    // parse the URL query parameters
    const searchParams = new URLSearchParams(document.location.search);

    const mode = searchParams.get("mode");
    const actionCode = searchParams.get("oobCode");
    const continueUrl = searchParams.get("continueUrl");

    // configure firebase SDK
    const auth = getAuth(initializeApp({ apiKey: firebaseConfig.apiKey }));

    // Handle the user management action.
    if (mode === "verifyAndChangeEmail" && actionCode) {
      handleVerifyEmail(auth, actionCode, continueUrl ?? undefined);
    } else if (mode === "recoverEmail") {
      setStatus("recoverEmailNotAllowed");
    } else {
      // invoke default handler
      const redirectURL = new URL(defaultHandlerURL);
      searchParams.forEach((value, key) => {
        redirectURL.searchParams.set(key, value);
      });
      window.location.replace(redirectURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const body = useMemo((): string => {
    switch (status) {
      case "initial":
        return "読み込み中…";
      case "success":
        return "メールアドレスの認証が完了しました。";
      case "expiredError":
        return "リンクの有効期限が切れています。";
      case "networkError":
        return "通信エラーが発生しました。";
      case "recoverEmailNotAllowed":
        return "メールアドレスの変更は、オンライン診療アプリの設定画面にて行なえます。";
      case "loading":
      default:
        return "メールアドレスの認証中…";
    }
  }, [status]);

  return (
    <div className="App">
      <header className="App-header">
        <p>{body}</p>
      </header>
    </div>
  );
}

export default App;
