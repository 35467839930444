export const defaultHandlerURL = process.env.REACT_APP_DEFAULT_HANDLER_URL!;
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
};
export const defaultMedicalAPIStage =
  process.env.REACT_APP_DEFAULT_MEDICAL_API_STAGE!;
export const medicalAPIEndpoint = (stage: string) =>
  process.env[`REACT_APP_${stage.toUpperCase()}_MEDICAL_API_ENDPOINT`]!;
export const medicalAPIKey = (stage: string) =>
  process.env[`REACT_APP_${stage.toUpperCase()}_MEDICAL_API_KEY`]!;
